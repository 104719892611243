#ContenedorPagina{
    /* Ajusta el tamaño de la imagen de fondo */
    background-color: gray;
    background-size: cover; /* Cubre todo el cuerpo */
    /* O puedes ajustar la posición de la imagen de fondo */
    background-position: center; /* Centra la imagen */
    
    /* Esto evitará que el fondo se desplace si el contenido es más grande que la ventana del navegador */
    background-attachment: fixed;
    display:flex;
    width: 100%;
    height:100vh;
}