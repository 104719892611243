/* Boton.css */
.cargando{
    box-sizing: border-box;
}
  
  .cargando {
    
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #fff;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 1s linear infinite;
    margin: auto;
    margin-top: -6px; /* Ajusta la posición del spinner */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }