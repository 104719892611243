
@font-face {
    font-family: 'Carattere';
    src: url('./../../FuentesTipograficas/Carattere-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Pridi';
    src: url('./../../FuentesTipograficas/Pridi-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    }

#dispositivoContenedor{
    /*background-color: red;*/
    /*background-image: url('Imagenes/imagen_empresarial.jpg'); */
    background-image: url('./../../Imagenes/imagen_empresarial.jpg'); 

    /* Ajusta el tamaño de la imagen de fondo */
    background-size: cover; /* Cubre todo el cuerpo */
    /* O puedes ajustar la posición de la imagen de fondo */
    background-position: center; /* Centra la imagen */
    
    /* Esto evitará que el fondo se desplace si el contenido es más grande que la ventana del navegador */
    background-attachment: fixed;
    display:flex;
    width: 100%;
    height:97vh;
}